import { useState } from "react";
import { useForm } from "react-hook-form";
import { SegmentedControl, Button, Group, Loader, Text } from "@mantine/core";
import { TextInput, Textarea } from "react-hook-form-mantine";
import { ajvResolver } from "@hookform/resolvers/ajv";
import { fullFormats } from "ajv-formats/dist/formats";
import {
  useGetTicketDownloadTemplate,
  useSendEmailToTickets,
} from "../../api/tickets";
import { modals } from "@mantine/modals";
import exampleQRCode from "../../assets/exampleQR.png";

const schema = {
  type: "object",
  properties: {
    subject: { type: "string", minLength: 1 },
    body: { type: "string", minLength: 1 },
    emailType: { type: "string", enum: ["ticketDownload", "regular"] },
  },
  required: ["subject", "body", "emailType"],
};

const SendEmail = ({
  ticketIds,
  eventId,
  onSuccess,
  onClose,
  ticketsCount,
}) => {
  const defaultValues = {
    subject: "",
    body: "",
    emailType: "ticketDownload",
  };

  const [showPreview, setShowPreview] = useState(false);

  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues,
    resolver: ajvResolver(schema, { formats: fullFormats }),
  });

  const { data: template, isPending } = useGetTicketDownloadTemplate();

  const { mutate: sendEmailToTickets, isPending: isSendingEmail } =
    useSendEmailToTickets();

  const emailType = watch("emailType");

  const recipientsCount =
    ticketIds === "all"
      ? `All ticket holders (${ticketsCount}) selected`
      : `${ticketsCount} ticket holders selected`;

  const onSubmit = (data) => {
    const formattedBody = data.body.replace(/\n/g, "<br />");

    try {
      sendEmailToTickets({
        ticketIds,
        eventId,
        email: {
          subject: data.subject,
          body: formattedBody,
          type: data.emailType,
        },
      });
      onSuccess();
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) {
    return <Loader />;
  }

  const toggleShowPreview = () => {
    setShowPreview((prev) => !prev);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SegmentedControl
        color="blue"
        fullWidth
        value={emailType}
        onChange={(value) => setValue("emailType", value)}
        data={[
          { label: "Ticket Download", value: "ticketDownload" },
          { label: "Regular", value: "regular" },
        ]}
        style={{ marginBottom: 10 }}
      />

      <div
        style={{
          backgroundColor: "#dde5f0",
          padding: "10px 20px",
          margin: "10px 0",
          fontSize: 14,
        }}
      >
        Hint: You can use {`{{ firstName }}`} and {`{{ lastName }}`} in the
        subject and body of the email.
      </div>
      <p
        style={{
          fontSize: 14,
          fontWeight: 500,
          marginBottom: 0,
        }}
      >
        Recipients
      </p>
      <p
        style={{
          fontSize: 14,
          margin: 0,
        }}
      >
        {recipientsCount}
      </p>
      <TextInput
        mt="md"
        name="subject"
        control={control}
        placeholder="Enter email subject"
        label="Subject"
      />
      {/* If emailType is regular, or emailType is ticketDownload and showPreview is false, then show the Textarea component. */}
      {(emailType !== "ticketDownload" ||
        (emailType === "ticketDownload" && !showPreview)) && (
        <Textarea
          mt="md"
          name="body"
          control={control}
          placeholder="Enter email body"
          label="Body"
          rows={5}
        />
      )}
      {/* If emailType is ticketDownload and showPreview is true, then hide the text area and show preview. */}
      {emailType === "ticketDownload" && showPreview && (
        <>
          <Text size="sm" mt="19" fw={500}>
            Body
          </Text>
          <div
            dangerouslySetInnerHTML={{
              __html:
                `<div style="text-align: center; margin-top: 40px">` +
                getValues("body").replace(/\n/g, "<br/>") +
                `</div>` +
                template
                  .replace(
                    /<img[^>]*>/g,
                    `<img src="${exampleQRCode}" alt="QR Code" title="Each attendee will receive a unique QR code" style="width: 150px; height: 150px;"/>`
                  )
                  .replace(
                    /href="[^"]*"/g,
                    'href="https://app.congrs.com/api/download"'
                  ),
            }}
          />
        </>
      )}
      <Group align="center" mt="md" style={{ paddingTop: 15, width: "100%" }}>
        {emailType === "ticketDownload" && (
          <Button variant="light" onClick={toggleShowPreview}>
            {showPreview ? "Show email editor" : "Show email preview"}
          </Button>
        )}
        <Group
          style={{
            marginLeft: "auto",
          }}
        >
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSendingEmail} type="submit" color="blue">
            Send
          </Button>
        </Group>
      </Group>
    </form>
  );
};

export const openSendMailModal = ({ ticketIds, eventId, totalTickets = 0 }) => {
  const ticketsCount = ticketIds === "all" ? totalTickets : ticketIds.length;

  modals.open({
    title: "Send Email",
    children: (
      <SendEmail
        ticketIds={ticketIds}
        ticketsCount={ticketsCount}
        eventId={eventId}
        onSuccess={() => modals.closeAll()}
        onClose={() => modals.closeAll()}
      />
    ),
  });
};

export default SendEmail;
