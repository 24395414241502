import { Menu, Skeleton, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useContext } from "react";
import React, { useEffect } from "react";

import { useUpdateUser } from "../../api/user";
import { AuthContext } from "../../services/context";
import updateUserConfig from "../../services/userConfig";
import AddNewItem from "./AddNewItem";

export default function EventCrumb({
  currentWorkspace,
  workspaces,
  events,
  isPendingEvents,
}) {
  const { user, setUser } = useContext(AuthContext);

  const [currentEventId, setCurrentEventId] = React.useState();
  const { mutate } = useUpdateUser({
    id: user.id,
    config: { hideNotification: true },
  });

  useEffect(() => {
    const currentEventIdFromConfig = user.config?.adminUI?.workspaces.find(
      (ws) => ws.id === currentWorkspace?.id
    )?.eventId;

    const currentEventId = currentEventIdFromConfig || events?.data[0]?.id;

    if (currentWorkspace != null) {
      setCurrentEventId(currentEventId);
      updateUserConfig({
        workspace: currentWorkspace,
        user,
        setUser,
        mutate,
        eventId: currentEventId,
      });
    }
  }, [events]);

  const defaultEvent = events?.data.find(
    (event) => event.id === currentEventId
  );
  const otherEvents = events?.data.filter(
    (event) => event.id !== currentEventId
  );

  const onChange = (id) => {
    setCurrentEventId(id);

    updateUserConfig({
      workspace: currentWorkspace,
      user,
      setUser,
      mutate,
      eventId: id,
    });
  };

  if (workspaces.length === 0 || !currentWorkspace?.id) return <>N/A</>;

  if (isPendingEvents) return <Skeleton height={20} width={100} />;

  if (events.pagination.total === 0) {
    return <Text>There are no events for this workspace.</Text>;
  }

  return (
    <EventMenu
      defaultEvent={defaultEvent}
      events={otherEvents}
      onClick={onChange}
    />
  );
}

const EventMenu = ({ defaultEvent, events, onClick }) => {
  return (
    <Menu withArrow>
      <Menu.Target>
        <div style={styles.breadcrumb}>
          <span>{defaultEvent?.name}</span>
          <IconChevronDown style={{ height: 12, width: 12, marginLeft: 5 }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {events.length > 0 && (
          <>
            <Menu.Label>Events</Menu.Label>
            <Menu.Divider />
            {events.map((event) => (
              <Menu.Item key={event.id} onClick={() => onClick(event.id)}>
                {event.name}
              </Menu.Item>
            ))}
            <Menu.Divider />
          </>
        )}
        <AddNewItem type="event" />
      </Menu.Dropdown>
    </Menu>
  );
};

const styles = {
  breadcrumb: {
    color: "grey",
    fontWeight: "bolder",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
