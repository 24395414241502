import { Button, Container, Group, Fieldset, Text } from "@mantine/core";
import { useForm, useWatch } from "react-hook-form";
import { Checkbox, TextInput, Textarea, Select } from "react-hook-form-mantine";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect } from "react";
import { omit } from "lodash";
import { useGetCurrentUser, useUpdateCurrentUser } from "../api/user";
import DeleteProfilePicture from "./Buttons/DeleteProfilePicture";
import DeleteAccountButton from "./Users/DeleteAccountButton";
import { useGetEvents } from "../api/event";
import { getCurrentWorkspace } from "../services/userConfig";
import LogOutButton from "./LogOutButton";
import ImageUpload from "./ImageUpload";
import { extractDirtyData } from "../utils/dirtyData";

const EditProfile = () => {
  const { data } = useGetCurrentUser();
  const privacyPolicyLink = "https://congrs.com/privacy-policy/";

  const {
    mutate,
    isPending: mutatePending,
    isSuccess,
  } = useUpdateCurrentUser();

  const form = useForm({
    defaultValues: {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      email: data?.email || "",
      occupation: data?.occupation || "",
      biography: data?.biography || "",
      discoverable: data?.discoverable || false,
      currentWorkspaceId: `${data?.config?.mobileUI?.currentWorkspaceId}` || "",
      currentEventId: `${data?.config?.mobileUI?.currentEventId}` || "",
      picture: data?.picture || "",
      isDataStorageConsentGiven: data?.isDataStorageConsentGiven || false,
    },
    resolver: zodResolver(
      z.object({
        firstName: z.string().min(1, { message: "First name is required" }),
        lastName: z.string().min(1, { message: "Last name is required" }),
        email: z
          .string()
          .email({ message: "Invalid email address" })
          .min(1, { message: "Email is required" }),
        occupation: z
          .string()
          .max(100, { message: "Occupation must be 100 characters or less" }),
        biography: z
          .string()
          .max(1000, { message: "Biography must be 1000 characters or less" }),
        discoverable: z.boolean(),
        currentEventId: z.string().nullable().optional(),
        picture: z.string().optional(),
        isDataStorageConsentGiven: z.boolean().refine((val) => val === true, {
          message:
            "You must consent to the processing of your profile data in order to save changes!",
        }),
      })
    ),
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = form;

  const selectedWorkspace = useWatch({
    control,
    name: "currentWorkspaceId",
  });

  const currentWorkspaceId =
    data?.role === "wsAdmin"
      ? getCurrentWorkspace(data)?.id
      : selectedWorkspace;

  const showEventSelect =
    data?.role === "sysAdmin" ||
    (data?.role === "wsAdmin" && currentWorkspaceId);

  const { data: events, isPending: eventsLoading } = useGetEvents({
    workspace: currentWorkspaceId,
  });

  const onSubmit = (formData) => {
    const config = {
      ...formData.config,
      mobileUI: {
        currentEventId: parseInt(formData.currentEventId),
        currentWorkspaceId: currentWorkspaceId,
      },
    };

    const payload = omit({ ...formData, config }, [
      "currentEventId",
      "currentWorkspaceId",
    ]);

    const dirtyPayload = extractDirtyData(payload, dirtyFields);

    mutate(dirtyPayload);
  };

  useEffect(() => {
    if (selectedWorkspace !== data?.config?.mobileUI?.currentWorkspaceId) {
      setValue("currentEventId", null);
    }
  }, [selectedWorkspace, setValue]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        {data?.role === "wsAdmin" && !currentWorkspaceId && (
          <Fieldset legend="Workspaces">
            <Text c="red">No assigned workspace, please contact support.</Text>
          </Fieldset>
        )}
        <Fieldset legend="Profile picture">
          <ImageUpload
            name="picture"
            control={control}
            defaultImage={data?.picture}
            label="Profile picture"
            resize
          />
          {data?.picture && (
            <DeleteProfilePicture userId={data?.id} my="md" size="sm" />
          )}
        </Fieldset>

        <Fieldset legend="Personal information">
          <TextInput
            name="firstName"
            control={control}
            placeholder="John"
            label="First name"
          />
          <TextInput
            mt="md"
            name="lastName"
            control={control}
            placeholder="Doe"
            label="Last name"
          />
          <TextInput
            mt="md"
            name="email"
            control={control}
            disabled
            label="Email"
          />
          <TextInput
            mt="md"
            name="occupation"
            control={control}
            placeholder="Software Engineer @ RoundCube"
            label="Occupation and company"
          />
          <Textarea
            mt="md"
            name="biography"
            control={control}
            placeholder="Tell us about yourself"
            label="Biography"
          />
          <Checkbox
            mt="md"
            name="discoverable"
            control={control}
            label="Discoverable"
            description="Allow other users to find you by searching your name"
          />
          <Checkbox
            mt="md"
            name="isDataStorageConsentGiven"
            control={control}
            label={
              <>
                I consent to the processing of my profile data by this
                application in accordance with the{" "}
                <a
                  href={privacyPolicyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#2e5bff",
                    textDecoration: "underline",
                  }}
                >
                  privacy policy
                </a>
                .
              </>
            }
          />

          {showEventSelect && (
            <Text style={styles.text}>
              Select
              {data.role === "sysAdmin" ? " Workspace and Event " : " Event "}
              which you want to display in mobile app
            </Text>
          )}

          {data?.role === "sysAdmin" && (
            <Select
              control={control}
              label="Workspace"
              placeholder="Select workspace"
              name="currentWorkspaceId"
              data={data.workspaces?.map((s) => ({
                value: `${s.id}`,
                label: s.name,
              }))}
              allowDeselect={false}
            />
          )}
          {!eventsLoading && showEventSelect && (
            <Select
              control={control}
              label="Event"
              placeholder="Select event"
              name="currentEventId"
              allowDeselect={false}
              data={events?.data?.map((s) => ({
                value: `${s.id}`,
                label: s.name,
              }))}
            />
          )}
        </Fieldset>

        <Fieldset legend="Actions" mb={20}>
          <Group
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              type="submit"
              variant="light"
              disabled={!isDirty || isSuccess}
              loading={mutatePending}
            >
              Save changes
            </Button>
            <Group style={{ flexDirection: "row", justifyContent: "end" }}>
              <DeleteAccountButton />
              <LogOutButton />
            </Group>
          </Group>
        </Fieldset>
      </form>
    </Container>
  );
};

export default EditProfile;

const styles = {
  text: {
    marginTop: 25,
    fontWeight: "500",
    fontSize: 12,
  },
};
