import { Button, Container, Fieldset, Text, Paper, Flex } from "@mantine/core";
import { useForm } from "react-hook-form";
import { Checkbox, TextInput, Textarea } from "react-hook-form-mantine";
import { useParams } from "react-router-dom";
import { IconAlertCircle } from "@tabler/icons-react";

import { useGetUser, useUpdateUser } from "../../api/user";
import DeleteProfilePicture from "../Buttons/DeleteProfilePicture";
import { extractDirtyData } from "../../utils/dirtyData";
import { ajvResolver } from "@hookform/resolvers/ajv";
import { fullFormats } from "ajv-formats/dist/formats";
import ImageUpload from "../ImageUpload";

const EditUserWrapper = () => {
  const { id } = useParams();

  const { data: user, isPending, isError, error } = useGetUser(id);

  if (isPending) {
    return <Text>Loading...</Text>;
  }

  if (isError) {
    return <Text c="red">{error.message}</Text>;
  }

  return <EditUser user={user} />;
};

export const EditUser = ({ user }) => {
  const defaultValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    occupation: user?.occupation || "",
    biography: user?.biography || "",
    discoverable: user?.discoverable || false,
    picture: user?.picture || "",
    isDataStorageConsentGiven: user?.isDataStorageConsentGiven || false,
  };

  const { mutate, isPending: mutatePending } = useUpdateUser({ id: user.id });

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues,
    resolver: ajvResolver(
      {
        type: "object",
        properties: {
          firstName: { type: "string", minLength: 1 },
          lastName: { type: "string", minLength: 1 },
          email: { type: "string", format: "email", minLength: 1 },
          occupation: { type: "string", maxLength: 100 },
          biography: { type: "string", maxLength: 1000 },
          discoverable: { type: "boolean" },
          picture: { type: "string" },
          isDataStorageConsentGiven: { type: "boolean" },
        },
      },
      { formats: fullFormats }
    ),
  });

  const onSubmit = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    mutate(dirtyData, {
      onError: () => {
        reset({ ...defaultValues });
      },
      onSuccess: () => {
        reset({ ...defaultValues, ...dirtyData });
      },
    });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset legend="Personal information">
          <ImageUpload
            control={control}
            name="picture"
            label="Profile picture"
            defaultImage={user?.picture}
            resize
          />
          {user?.picture && (
            <DeleteProfilePicture userId={user?.id} my="md" size="sm" />
          )}
          <TextInput
            mt="md"
            name="firstName"
            control={control}
            placeholder="John"
            label="First name"
          />
          <TextInput
            mt="md"
            name="lastName"
            control={control}
            placeholder="Doe"
            label="Last name"
          />
          <TextInput
            mt="md"
            name="email"
            control={control}
            placeholder="john@doe.com"
            label="Email"
            description="Use with caution, editing email will require re-verification"
          />
          <TextInput
            mt="md"
            name="occupation"
            control={control}
            placeholder="Software Engineer @ RoundCube"
            label="Occupation and company"
          />
          <Textarea
            mt="md"
            name="biography"
            control={control}
            placeholder="Tell us about yourself"
            label="Biography"
          />
          <Checkbox
            mt="md"
            name="discoverable"
            control={control}
            label="Discoverable"
            description="Allow other users to find you by searching your name"
          />
          <Checkbox
            mt="md"
            name="isDataStorageConsentGiven"
            control={control}
            disabled={true}
            label="I consent to the processing of my profile data by this application in accordance with the privacy policy."
            description={
              <Paper shadow="md" p="sm" withBorder mt="xs">
                <Flex direction="row" align="center" gap={10}>
                  <IconAlertCircle />
                  <Text style={{ fontSize: 13 }}>
                    You are editing this profile as a data controller. Changes
                    made here are your responsibility under applicable data
                    protection regulations.
                  </Text>
                </Flex>
              </Paper>
            }
          />
        </Fieldset>
        <Button
          mt="md"
          type="submit"
          variant="light"
          disabled={!isDirty}
          loading={mutatePending}
        >
          Save changes
        </Button>
      </form>
    </Container>
  );
};

export default EditUserWrapper;
