import { useState, useContext, useRef, useEffect } from "react";
import TicketsTable from "../components/Tickets/Table";
import { useGetAllTickets } from "../api/tickets";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useDebouncedValue } from "@mantine/hooks";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import {
  IconFileExport,
  IconFileImport,
  IconMail,
  IconPlus,
  IconTableOptions,
} from "@tabler/icons-react";
import { openExportTicketsModal } from "../components/Tickets/ExportTicketsModal";
import { openSendMailModal } from "../components/Tickets/SendEmail";

function Tickets() {
  const tableRef = useRef();
  const [selectedTickets, setSelectedTickets] = useState([]);

  const handleAddNewTicket = () => {
    tableRef.current?.addNewRow();
  };

  useSetActionButtons([
    {
      label: "Import",
      navigate: "./tickets/import",
      icon: <IconFileImport />,
    },
    {
      label: "Table Settings",
      navigate: "./tickets/settings",
      icon: <IconTableOptions />,
    },
    {
      label: "Export to CSV",
      onClick: () => {
        openExportTicketsModal({ eventId: currentEventId });
      },
      icon: <IconFileExport />,
    },
    {
      label: "Send Emails",
      onClick: () => {
        openSendMailModal({
          ticketIds: selectedTickets,
          eventId: currentEventId,
          totalTickets: data?.pagination?.total,
        });
      },
      icon: <IconMail />,
    },
    {
      label: "Create",
      onClick: handleAddNewTicket,
      icon: <IconPlus />,
    },
  ]);

  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 50 });
  const [sorting, setSorting] = useState({
    columnAccessor: "firstName",
    direction: "asc",
  });
  const [filter, setFilter] = useState(null);
  const [debouncedFilter] = useDebouncedValue(filter, 200);

  const { data, isPending } = useGetAllTickets({
    eventId: currentEventId,
    ...pagination,
    sort: sorting,
    filter: debouncedFilter,
  });

  // When changing an event, we need to reset the selected tickets
  // and pagination
  useEffect(() => {
    setSelectedTickets([]);
    setPagination({ page: 1, limit: 50 });
    setFilter(null);
    setSorting({ columnAccessor: "firstName", direction: "asc" });
  }, [currentEventId]);

  return (
    <TicketsTable
      ref={tableRef}
      data={data}
      pagination={{ data: pagination, setPagination }}
      sorting={{ data: sorting, setSorting }}
      isPending={isPending}
      actions={{
        openSendEmailModal: ({ ticketIds }) => {
          openSendMailModal({ ticketIds, eventId: currentEventId });
        },
      }}
      filter={{ data: filter, setFilter }}
      selectedIds={selectedTickets}
      onSelectedIdsChange={(selectedTickets) => {
        setSelectedTickets(selectedTickets);
      }}
    />
  );
}

export default Tickets;
