import { TextInput, Select, MultiSelect } from "@mantine/core";
import { useRef, useEffect, useState } from "react";

export const EditableCell = ({
  isExisting,
  value,
  onChange,
  error,
  inputProps = {},
  onSave,
  onCancel,
  type = "text",
  options = [],
  render,
  autoFocus,
}) => {
  const inputRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  if (isExisting) {
    // If the cell has a custom render function, use it
    if (render) {
      return render();
    }
    return value;
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      if (!isSaving) {
        // Prevent multiple save events
        setIsSaving(true);
        onSave?.();
      }

      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    } else if (e.key === "Escape") {
      onCancel?.();
    }
  };

  if (type === "select") {
    return (
      <Select
        ref={inputRef}
        value={value}
        onChange={onChange}
        data={options}
        error={error}
        onKeyDown={handleKeyDown}
        {...inputProps}
      />
    );
  }

  if (type === "multiselect") {
    return (
      <MultiSelect
        ref={inputRef}
        defaultValue={value?.map((tag) => tag.label) || []}
        onChange={(selectedLabels) => {
          const selectedTags = options.filter(
            (option) =>
              selectedLabels.includes(option.label) ||
              selectedLabels.includes(option.value)
          );

          onChange(selectedTags.map((tag) => tag.value));
        }}
        data={options}
        error={error}
        onKeyDown={handleKeyDown}
        comboboxProps={{ width: 150, position: "bottom-start" }}
        checkIconPosition="start"
        {...inputProps}
      />
    );
  }

  return (
    <TextInput
      ref={inputRef}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={error}
      onKeyDown={handleKeyDown}
      {...inputProps}
    />
  );
};
