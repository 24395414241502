import { ActionIcon, Badge, Group, Tooltip, Text, Flex } from "@mantine/core";
import { IconBrush, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../DataTable";
import { useDeleteEvent } from "../../api/event";
import deleteModal from "../deleteModal";
import updateConfig from "../../services/userConfig";
import { useContext } from "react";
import { AuthContext } from "../../services/context";
import { useUpdateUser } from "../../api/user";

function EventsTable({
  data,
  isLoading,
  isSysAdmin,
  page,
  setPage,
  sortStatus,
  setSort,
}) {
  const { mutate, isError, isSuccess, error } = useDeleteEvent();
  const { user, setUser } = useContext(AuthContext);
  const { mutate: updateUser } = useUpdateUser({
    id: user.id,
    config: { hideNotification: true },
  });

  const navigate = useNavigate();

  const statusColor = (status) => {
    switch (status) {
      case "active":
        return "green";
      case "closed":
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <>
      <DataTable
        records={data?.data}
        columns={[
          {
            accessor: "name",
            sortable: true,
            render: (event) => (
              <Flex direction="row" gap="sm">
                <Text size="sm">{event.name}</Text>
                <Badge variant="dot" color={statusColor(event.status)}>
                  {event.status}
                </Badge>
              </Flex>
            ),
          },
          {
            accessor: "actions",
            width: "0%",
            render: (event) => (
              <Group justify="center" wrap="nowrap">
                <Tooltip label="Cutomize">
                  <ActionIcon
                    size="md"
                    variant="light"
                    onClick={() => {
                      updateConfig({
                        eventId: event.id,
                        user,
                        setUser,
                        mutate: updateUser,
                        workspace: user.config?.adminUI?.workspaces.find(
                          (ws) =>
                            ws.id === user.config?.adminUI?.activeWorkspaceId
                        ),
                      });

                      navigate("/app/events/customize");
                    }}
                  >
                    <IconBrush />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="red"
                    disabled={!isSysAdmin}
                    onClick={() => {
                      deleteModal(
                        event,
                        `delete '${event.name}' event`,
                        mutate,
                        isError,
                        error,
                        isSuccess
                      );
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        page={page}
        onPageChange={setPage}
        recordsPerPage={data?.pagination.perPage}
        totalRecords={data?.pagination.total}
        fetching={isLoading}
        sortStatus={sortStatus}
        onSortStatusChange={setSort}
      />
    </>
  );
}

export default EventsTable;
